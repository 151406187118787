import React from 'react';
import './App.css'; // Assuming the CSS provided is saved in App.css
import './New.css'; // Assuming the CSS provided is saved in App.css
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPatreon, faTwitter, faItchIo, faGithub, faLinkedin, faGoogleDrive, faDeviantart } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import almanac from './images/almanac.webp' 
import dwarves from './images/Dwarves_icon01.webp' 
import hb from './images/cover_art_03.png' 
import lav from './images/ms-icon-bl.png' 
import lav_nega from './images/ms-icon-nega.png' 
import lotd from './images/lotd.png' 
import stalwart from './images/stalwart.png' 
import writing from './images/writing.png' 
import far_verona from './images/far_verona.webp' 
import maturation_fiasco from './images/maturation_fiasco.png' 
import trig_lore from './images/trig_logo_vector.png' 
import translation from './images/translation.webp' 
import tecendil from './images/tecendil.png' 
import flamberg from './images/flaberg.jpg' 


const TopBar = () => {
    return (
        <header className="main_header">
            <a href="/" className="logo"><span>TraderVic's</span> Narrative Nexus</a>
            <nav>
                <Link to="/news" className="no-link">News</Link>
                <Link to="/blog" className="no-link">Blog</Link>
                <Link to="/bio" className="no-link">Bio</Link>
            </nav>
        </header>
    );
};
const Bio = () => {
    return (
        <div className="regular_page">
            <div className="page_title"><h1>About me</h1></div>
            <article className="regular_content">
                <dl>
                    <dt>It's what I do</dt>
                    <dd>I've been developing sci-fi and fantasy worlds and the stories within them all my life. It's like an itch that's hard to scratch, and then, before you know it, it's way after midnight and you're staring at another half-finished draft wondering, when you're going to have the time to polish it.</dd>
                    <dt>Early Influences and Interests</dt>
                    <dd>My early years were filled with imaginative play, where I introduced rules and defined specific traits for characters, foreshadowing my future in game design and development.</dd>
                    <dd>My fascination with games began with the Commodore C64, where I spent countless hours on classics like Giana Sisters, Monty Python, and Parandroid. This early exposure to interactive entertainment was complemented by my love for narrative-driven media, such as He-Man, Star Trek: TNG, Buffy the Vampire Slayer, and various animated series like Dexter's Lab and Swat Kats.</dd>
                    <dt>Interplay and Black Isle</dt>
                    <dd>The games that had the greatest influence on my future career and creative decisions have been <b>Baldur's Gate</b> and <b>Fallout</b>. </dd>
                    <dd><b>Baldur's Gate</b> introduced a world, which through detailed item descriptions and a myriad of NPCs has provided hundreds of disconnected stories, that made the world feel rich, alive and full of untold ancient history.</dd>
                    <dd><b>Fallout</b> did the same, and more - it showed me how a distinct visual design can underline the story; it highlighted, how the characters you meet can be the heroes of the story; it showed, how a story doesn't have to be about characters, but entire societies. And yes, each object on a Fallout map had a description, which made the world that much richer, and because of the humor within, not much unlike <i>Terry Pratchett's</i> footnotes, which I already adored.</dd>
                    <dt>Education</dt>
                    <dd>In primary school, I voraciously read over 400 books, earning a trophy for "Best Reader." My literary interests spanned Greek, Roman, and Norse myths, as well as contemporary series like The Witcher, Wheel of Time, and Terry Pratchett's Discworld novels. </dd>
                    <dd>I later pursued a degree in Mathematics, graduating with top grades and gaining advanced problem-solving skills, alongside general programming and scripting knowledge, mostly materialized in bash, VBS, Pascal and C++, but easily transferrable to other languages.</dd>
                    <dt>Roleplaying and Game Design</dt>
                    <dd>My journey into roleplaying games began early, in 6th grade, with Warhammer RPG and expanded into EarthDawn and Cyberpunk 2020. For over a decade I played many characters and created emotionally charged scenarios as a Game Master. I attempted to design a Wheel of Time RPG system, but my original group disbanded before I had a chance to try it out. </dd>
                    <dt>Neverwinter Nights</dt>
                    <dd>In the early 2000s I contributed significantly to a Neverwinter Nights persistent game server, scripting and world-building for a dedicated player base in collaboration with 2 other like-minded developers over the span of 3 years. We've built over 130 areas, wilderness focused mostly on hack'n'slash content, and three major cities that contained all the major questlines.</dd>
                    <dt>Writing and Narrative Development</dt>
                    <dd>I started writing short stories and novels, driven by my love for storytelling. Though unpublished, this experience honed my narrative skills, which I later applied to designing LARP games and analyzing storytelling components in various narrative games like <b>Dungeon World</b> and <b>Blades in the Dark.</b></dd>
                    <dt>Professional Experience</dt>
                    <dd>My professional career began in IT support, where I leveraged scripting and automation to enhance office productivity on many levels. This proactive attitude earned me many commendations and thrusted my career forward. </dd>
                    <dd>Later I developed applications in C#, managed software rollouts, and automated critical business processes. My expertise in automation led me to the Internal Automation Department (in support of over 200k users), where I planned, coordinated, built and managed large-scale projects for various web applications and online worker-services using <b>C#, Angular</b> and later <b>ReactJS, NodeJS</b> and finally <b>Python</b>. </dd><dd>Despite wearing many hats, I was always mainly an infrastructure designer/engineer and software developer, coding the bricks from which the projects were built.</dd>
                    <dt>Rollplay: Far Verona</dt>
                    <dd>With the birth of my children the time spent on creative endeavours outside of work shrunk, but I was already somewhat invested in watching various TTRPG's being played on the <a href="https://www.youtube.com/channel/UCQj4ZJd2QxRHwVYQbMvcKdQ">itmeJP's</a> Youtube channel. </dd>
                    <dd>One of those shows was called <b>Far Verona</b> and it introduced a fun way for viewer participation, where any subscriber could make in world decisions via Discord. That in turn spawned a whole community of 2k+ creatives constantly writing lore for the show, that with the GMs approval was considered canon. </dd>
                    <dd>For almost three years that became my favorite pastime and during that period I catered to that vast repository of lore/knowledge, managed contributions and participated or led international meetings centered on developing this large fictional universe.</dd>
                    <dd>It was a time of collaboration, a time of learning people's expectations of world-building and storytelling. Articles and stories were written, read, and critiqued. Constructive arguments were made. Philosophies were created, scrutinized, and forgotten; societies were built, developed, and destroyed in unnecessary conflict; planets were teraformed, abandoned, decayed, and rediscovered; and all of this was done in a feverish frenzy, that only comes when people from all over the world are trying to create something truly unique. </dd>
                    <dd>It was the best of times.</dd>
                    <dd>But you know what they say about all the good things.</dd>
                    <dt>It's what I do</dt>
                    <dd>Something like Far Verona doesn't happen on it's own, but I feel like it should happen more often. And so I started on <a href="http://localhost:3000/lavernica"><b>Lavernica</b></a>, started on some boardgames, on some one-page narrative games. I investigated all of my favorite authors, both writers and game designers, started taking notes. And yeah, I sometimes still find myself staring at another half-finished draft at 3AM, but I guess that's how it goes.</dd>
                    <dt>Leadership and Technology</dt>
                    <dd>Mainly a coder, I often played the role of a Technical Lead, where I managed teams and cross-department projects. Every time I took ownership of a project, I made sure to be available for team members, so I can give them directions, and I have taken the responsibility of integrating all of the delivered code into a final product. </dd>
                    <dd>I proactively extended my oversight into areas I felt needed it, often predicting otherwise unanticipated risks that I either raised with product owners or prepared contingency plans for in advance, often gathering praise. </dd>
                    <dd>In recent years, the scope of our automation efforts has changed as projects have begun to incorporate AI technologies like transformer models (such as Google's BERT) for classification and decision automation, and more recently, large language models.</dd>
                    <dt>Current Endeavors</dt>
                    <dd>Currently I am assigned to work on applications that are utilising large language models and Retrieval Augmented Generation for knowledge processing and automated decision making, designing and developing intergations with our former automations as well as telephony systems and speech-to-text engines</dd>
                    <dd>Outside of my day job I have multiple ongoing project, but I am focused on designing <b><a href = "http://localhost:3000/heroic_blades">Heroic Blades</a></b>, a game that combines the narrative depth of old school games like Baldur's Gate with faction mechanics that incentivise the GM to create more complex worlds, and with the various mechanics of <b>Blades in the Dark</b>.</dd>
                </dl>
            </article>
        </div>
    )
}
const News = () => {
    return (
        <div className="regular_page">
            <div className="page_title"><h1>Log</h1></div>
            <article className="regular_content">
                <dl>
                    <span class="news_date" href="">2024-FEB-03</span>
                        <dt>Blog</dt>
                        <dd>I am doing a lot of ground design work for my various projects, and a lot of documents and thought provoking notes gets created.</dd>
                        <dd>As a result, I am adding a blog to the site.</dd>
                    <span class="news_date" href="">2024-DEC-28</span>
                        <dt>Life</dt>
                        <dd>Work before christmas was a nightmare with way too much overtime. We succeeded at something rather impressive, but te cost in time and health was too great, not to mention that the time I could spare for my projects was reduced to zero.</dd>
                        <dd>Lavernica for PC is on hold, and I am thinking of switching gears</dd>
                    <span class="news_date" href="">2024-NOV-01</span>
                        <dt>Lavernica for PC</dt>
                        <dd>Writing on <i>Heroic Blades</i> halted to a grind.</dd>
                        <dd>But as I was working on the World section, I kept coming back to the Downrtime rules which heavily borrow from my faction game <i>Lavernica</i>. As I was trying to simulate some situations that should or shouldn't be possible in the game in python, I determined, that it would be much easier to do that when using a GUI.</dd>
                        <dd>Before i knew it I had a semi-working version of Lavernica written in <b>Love2D</b>, which is a LUA game engine / game framework.</dd>
                        <dd>This isn't a production ready game, but with some work it could be. Very Exciting!</dd>
                    <span class="news_date" href="">2024-JUL-21</span>
                        <dt>Testing Results</dt>
                        <dd>First of all, the tests went really well. I had 5 groups sharing my 10 printouts of the baseic ruleset, and the general feedback was promising.</dd>
                        <dd>Worst part was - they all said they need more. There's not enough world to go on, not eanough random tables to prompts them to move forward, and the GMs want me to expand the GMing section.</dd>
                        <dd>Back to the drawing board!</dd>
                    <span class="news_date" href="">2024-MAY-05</span>
                        <dt>First Draft</dt>
                        <dd>Exciting news!</dd>
                        <dd>Got the main rules and playbooks drafted for<b>Heroic Blades</b>. Hope to get the group toghether for a playtest asap.</dd>
                    <span class="news_date" href="">2024-APR-06</span>
                        <dt>Heroic Blades</dt>
                        <dd>Renaming the main project to <b>Heroic Blades</b>.</dd>
                        <dd>The new name is a bit on the nose, but I had to change it - someone pointed out abbreviating the game name to <b>BS</b> is not a great connotation.</dd>
                    <span class="news_date" href="">2024-JAN-13</span>
                        <dt>Identify spell and the power of digressions</dt>
                        <dd>When mixing Lavernica with BitD, I can't help but come back to Baldur's Gate. Building <b>Blade and Steading</b> is going to require more than just rules - BitD has a built in setting that's strongly integrated with the rules, and I can't imagine my game any other way than set in a high fantasy world that's very much alike to Forgotten Realms. But my best memories from that place are tied to Baldur's Gate.</dd>
                        <dd>I was trying to dissect, what it is that I enjoyed so much about this game, and turns out that one of the highlights was reading all the books, and, even more so, the items' descriptions after an item was identified. Which was funny, because <i>Identify</i> wouldn't normally provide the caster with the item's history, just the item's magical powers information. The fact that identify shows an ite's full description is more of a technical shortcoming.</dd>
                        <dd>But what's so fun about item descriptions? Well, turns out that they're the building blocks of that immense feeling of depth the world of Baldur's Gate endows upon its player. And this is the same for Morrowind with all the in-world books and Fallout, with the ability for the player to examine practically any object in the game to obtain its (often humorous) description.</dd>
                        <dd>This is even the same for books. Any book that shows you a map, a tattered copy of an in-world document or other epistolary element - those are the things that add depth to the experience. And Terry Pratchett is the king of such digressions with his fabulous footnotes.</dd>
                        <dd>What this means for <b>Blade and Steading</b> though? Well - I need an in-game digression mechanic that not only helps create smaller stories aside from the main narrative, but encourages it. So there's nt only going to be random generation for prompting such intermissions, but a reward system of some sort that will encourage its use.</dd>
                    <span class="news_date" href="">2023-DEC-31</span>
                        <dt>Blade and Steading</dt>
                        <dd>Going full in on this idea. It's too great to pass up.</dd>
                        <dd>Also: Discovered Tim Cain, the producer of the original <i>Fallout</i>, has a Youtube channel and shares some valuable insights.</dd>
                        <dd></dd>
                    <span class="news_date" href="">2023-DEC-27</span>
                        <dt>Too much time and too many questions</dt>
                        <dd>That's what always happens during Christmas, right? You finally have the free time to finally complete a project, but instead you spend it all on designing something new.</dd>
                        <dd>The <b>BitD</b>/Lavernica game is codenamed <b>Sword and Steading</b>. I know what to do with the faction mechanics, but <b>stress</b> and <b>vices</b> are a big question mark. I love them in the original <b>Blades</b>, but they barely fit as a constant in a regular <i>High Fantasy</i> story.</dd>
                    <span class="news_date" href="">2023-DEC-25</span>
                        <dt>Merry Christmas</dt>
                        <dd>This was a terrible year for my various hobbies. But one good thing that happened is I discovered <a href="https://youtube.com/playlist?list=PLz3Be--ot61Nip0tbIMHcVnZWz3LOE_rb&si=g_B9FIOivxSbg5CY"><i>Haunted City</i></a>. </dd><dd>And here I was thinking I'm crazy, stubbornly saying <b>Blades in the Dark</b> are a revolutinary game, and <b>Jared</b>  from the <i>Glass Cannon Network</i> just did all the things with <b>Blades in the Dark</b> that I wanted to do, and more.</dd><dd>This leaves me no choice: I need to do a game that combines <i>Lavernica</i> (my high fantasy faction game) with <b>Blades in the Dark</b>.</dd>
                    <span class="news_date" href="">2023-OCT-27</span>
                        <dt>Day job vs Creative Work - 25:1</dt>
                        <dd>It's been a while. Adding some of my old stuff gradually. Not all of it, but I'll try to share some of the cool things I've been doing.</dd>
                    <span class="news_date" href="">2023-JAN-13</span>
                        <dt>Kickoff!</dt>
                        <dd>Publishing a personal website. Thought about keeping the name as just <b>Lavernica</b>, but there's going to be more stuff here than just the stories about Lav or the faction game, so I probably will change the name at one point.</dd>
                </dl>
            </article>
        </div>
    )
}
const HeroicBlades = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>Heroic Blades</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/HeroicBlades_Intro_pre-layout.pdf`} 
            download="HeroicBlades_Intro_pre-layout.pdf">
                <i className="fas fa-download"> Preview</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>What is <b>Heroic Blades</b>?</dt>
                    <dd>It's a <i>Forged in the Dark</i> tabletop RPG set in a High Fantasy setting that follows the adventures and perils of a faction of experienced adventurers that's well equipped to take on the establishment and on its course to redefine the balance of power in the world.</dd>
                    <dt>Why are you making this game?</dt>
                    <dd>Because I love <i>Baldur's Gate II</i>, love <i>Throne of Bhaal</i> and love <i>The Witcher Saga</i> AND because <b>Blades in the Dark</b> brought some never seen before innovation to TTRPG gaming. </dd>
                    <dd>I'd be happy to play a game that lets me relive those Old School AD&D vibes, immerses me in either a perilous adventure or the depths of a political plot, encourages both the GM and the Players to portray a rich world by making it easy to create those smaller stories within the grander narrative, while allowing the flexibility and freedom <b>Blades in the Dark</b> so beautifully enables. Problem is, such a game doesn't exist. <br/>So really, I don't have a choice but to make one. </dd>
                    <dt>Features</dt>
                    <dd>The game will introduce the well known classes of OSR: <b>Bard, Fighter, Priest, Ranger, Thief </b>and<b> Wizard</b>, but will also make the player's faction a character in the game, with a playbook type to pick from: <b>Diplomats, Explorers, Guardians, Infiltrators, Mercenaries </b>and<b> Vigilantes</b>.</dd>
                    <dd>The characters in <b>Heroic Blades</b> are far from starting level adventurers. They are already on a path to fame (or infamy) in the realm, their Faction being an ally, an obstacle or at least an undeniable fact that the powerful and the influential that reign the world cannot ignore.</dd>
                    <dd>A Faction doesn't only include the players' characters - it's their hideouts, possessions, less prominents party members, enemies, contacts and other assets. During play you discover, if the faction survives the cutthroat politics of the cities of Acheron and the dangers of adventuring in it's untamed wilderness.</dd>
                    <dt>Status</dt>
                    <dd>The game development is in steady progress and periodically undergoes internal playtests as new components are added to the rulebook.</dd>
                    <dd>Open beta testing to be expected in early 2025.</dd>
                </dl>
            </article>
        </div>
    )
}
const Lavernica = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Lavernica</h1></div>
                <a 
                href={`${process.env.PUBLIC_URL}/docs/Lavernica_pocket_version.pdf`} 
                download="Lavernica_pocket_version.pdf">
                    <i className="fas fa-download"> Preview</i>
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>What is Lavernica?</dt>
                    <dd>It's a <b>Faction Game</b> set in a High Fantasy world.</dd>
                    <dd> In the game each Faction is controlled by a <b>single strong-willed individual</b> that is the driving force for the Faction. This may be a king, a general, a master spy, a godfather figure, a wizard or a prophet, or any other individual that can exert their control over others by either military, economic, arcane, ideological or decitful means. </dd>
                    <dd>Each faction also controls various assets like <b>gangs, armies or associates</b> and the game spans across vast areas of land, with great focus on Steadings - villages, keeps, cities or towns.</dd>
                    <dt>But what is it for?</dt>
                    <dd>While the intention was to create a GM tool that would help to track and maintain a sandbox-like world in a campaign, <b>Lavernica</b> can also be easily played as a solo game or with friends. Initially, it was intended for <i>Dungeon World</i> or its extensions like <i>Perilous Wilds</i>, but it is currently compatible with any fantasy setting with little or no adaptation required.</dd>
                    <dt>Status</dt>
                    <dd>The game is currently in playtesting, with some issues being fixed as they're identified.</dd>
                </dl>
            </article>
        </div>
    )
}
const Almanac = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Almanac</h1></div>
                <a href={`https://github.com/andrzej-zielinski/almanac`}>
                    <FontAwesomeIcon icon={faGithub} className="fa-fw" />
                    Github
                </a>
            </div>

            <article className="regular_content">
                <dl>
                    <dd>A python package that implements random generator tables from various games and rulebooks, and aims to help GMs in generating an emergent world quickly.</dd>
                    <dt>Perilous Wilds</dt>
                    <dd>Allows to generate Realms, Regions, Areas, and all the Dangers and Discoveries within.</dd>
                    <dt>Into the Odd</dt>
                    <dd>Implements all the random generation tables collectively called <i>The Oddpendium</i>.</dd>
                    <dt>Blades in the Dark</dt>
                    <dd><i>Blades in the Dark</i> has some random generation tables which are implemented in the package.</dd>
                    <dt>Status / Plans</dt>
                    <dd>With the base tables already added for the three above sourcebooks I'm in the process of adding more complex generators and creating objects, that support the resulting entities. The plan is to normalize those objects, so they can fit all of the current and future entities.</dd>
                    <dd>Also, other rulebooks to be added to Almanac (in the pipeline):<br/>
                        - <b>Artefact: Burrows and Bloodlines</b> - a couple of society generation tables.<br/>
                        - <b>Scarlet Heroes (Solo Gaming)</b> - some random world generating tables.<br/>
                        - <b>Shadowdark</b> - a large repository of random world generating tables.<br/>
                        - <b>Mythic: GM Emulator</b> - a large repository of random generation tables.<br/>
                        - <b>Worlds Without Number</b> - a large repository of random world generating tables.<br/>
                    </dd>
                    <dd>Finally, a plan to create my own generators and add them to the package, or even to created a similiar sc-fi oriented package exist, but since this tool is currently being used more that it is developed, there's no firm implmenentation deadline for any of those features.</dd>
                    
                </dl>
            </article>
        </div>
    )
}
const Dwarves = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>Beards, Picks and Axes</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/Beards Picks and Axes_preview.pdf`} 
            download="Beards Picks and Axes_preview.pdf">
                <i className="fas fa-download"> Preview</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>What is this?</dt>
                    <dd>This is a game about dwarves. <br/>There's an underground fortress, a forge, a smelter and a tavern. The dwarves are warriors and they are miners. There's dwarf women which all dwarfs respect, protect and cherish. There's kobolds, goblins and orcs. <br/>There's also a dragon. <br/>And there is gold.</dd>
                    <dt>Who is this for?</dt>
                    <dd>I guess it's for kids. At least that was the initial reason I made it - there's a lot of dwarf games that I cannot play with my kids, and I wanted to make one that we can play with the entire family.</dd>
                    <dt>Why dwarves?</dt>
                    <dd>They have some appeal to me. There's a certain honesty to their way of life, balancing the danger as warriors and miners.</dd>
                    <dt>Why gold and why the dragon?</dt>
                    <dd>It's kind of iconic for dwarves, right?</dd>
                    <dt>Why dwarven women are like this in your game?</dt>
                    <dd>Because I needed a mechanic like this in the game, but also because at the time I had to teach my son how he should and shouldn't treat girls in preschool, and this was a good segway.</dd>
                    <dt>Status</dt>
                    <dd>
                        Mechanically it's finished. Played for a couple of years by multiple groups and adjusted to mitigate some problems. I anticipate no further changes in that area.<br/>
                        The board, tokens and the dwarf cards - the entire imagery really, plus the layout - all of that is just placeholders for now.
                    </dd>
                    
                </dl>
            </article>
        </div>
    )
}
const HeartOfGold = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>Heart  of Gold</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/Lavernica_Heart_of_Gold.pdf`} 
            download="Lavernica_Heart_of_Gold.pdf">
                <i className="fas fa-download"> Download</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>What's this?</dt>
                    <dd>It's a short story set in a <i>high fantasy</i> world, first of a series, about an elven warrior, turned to burglar, and about the people she meets on the way.</dd>
                    <dt>Just one story? Is there more?</dt>
                    <dd>Tricky question. There is more, but it's all over the place. I cannot share anything more until I can make some decisions about what Lavernica's life will bring, and then I'll finalize the stories and publish them all.</dd>                    
                    <dt>Who were the Plague Cultists?</dt>
                    <dd>You'll have to wait to find out.</dd>                  
                    <dt>Does Justine really hear her goddess or is she just making it up?</dt>
                    <dd>I honestly don't know. Does any cleric really have this type of bond with their Deity, or is it just in their head?</dd>
                </dl>
            </article>
        </div>
    )    
}
const FarVerona = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>Far Verona</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/FarVerona_works.zip`} 
            download="FarVerona_works.zip">
                <i className="fas fa-download"> Download</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>What's Far Verona?</dt>
                    <dd>Well, <b>Rollplay: Far Verona</b> was a worldwide community of 2k+ creatives I participated in. As the main cast was roleplaying in a sci-fi world on Twitch, the viewers had some limited governance of in-world events as well as the permission to write lore. It was a great (and successful) collaborative world-building experiment. You can read more about it <Link to={"/trig_lore"}>here</Link>.</dd>
                    <dt>Stories</dt>
                    <dd>I've been very invested in the world-building activities for Far Verona, and running multiple collaborative projects when the news struck, that the show is over and the community shitfs attention to another setting. Despite the collaboration being over I continued to work on some of the articles, stories and characters I already developed, and as I did it all grew into it's own thing.</dd>
                    <dd>I have a large number or stories and two novels, all in various stages of completion, that I plan to finish and publish at some point. You can download a small preview of those             <a 
            href={`${process.env.PUBLIC_URL}/docs/FarVerona_works.zip`} 
            download="FarVerona_works.zip">here</a>.</dd>
                    <dt>Far Verona web-app</dt>
                    <dd>The so called <b>Faction Game</b> of Far Verona was what originally spawned the lore making community. That game was governed by a single Game Master during monthly livestreams on Twitch and via a dedicated Discord server.</dd>
                    <dd>My lifetime of automation always led me to believe, that tooling or even an entire framework could be developed to facilitate better management of the <b>Faction Game</b>. I started to build a web application that would link to both the Fandom wikia and the google spreadsheets used for keeping the game's state. When the community collapsed I didn't stop working on the app hoping that I could reignite the flame, but things like that are easier said than done.</dd>
                    <dd>The <a href="https://farverona.com/">Far Verona application</a> project is on hold, with my occasional inputs being limited to rewriting some parts of the code when time allows. But since there are other projects that take priority, the application is far from complete.</dd>
                </dl>
            </article>
        </div>
    )
}
const Tecendil = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Polish Tengwar Mode</h1></div>
                <a href="https://github.com/andrzej-zielinski/tecendil-js">
                    <FontAwesomeIcon icon={faGithub} className="fa-fw" />
                    Github
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>Tecendil</dt>
                    <dd>The most up to date and accurate tengwar transriber. Check it out at <a href="https://www.tecendil.com/">tecendil.com</a></dd>
                    <dt>Tengwar</dt>
                    <dd>The Tengwar (/ˈtɛŋɡwɑːr/) script is an artificial script, one of several scripts created by J. R. R. Tolkien, the author of The Lord of the Rings.</dd>
                    <dd>Within the fictional context of Middle-earth, the Tengwar were invented by the Elf Fëanor, and used first to write the Elven tongues Quenya and Telerin. Later a great number of Tolkien's constructed languages were written using the Tengwar, including Sindarin. Tolkien used Tengwar to write English: most of Tolkien's Tengwar samples are actually in English.</dd>
                </dl>
            </article>
        </div>
    )
}
const Maturation = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Maturation Fiasco</h1></div>
                <a href={`https://tradervic12.itch.io/maturation-fiasco`}>
                    <FontAwesomeIcon icon={faItchIo} className="fa-fw" />
                    ITCH.IO
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>What is this?</dt>
                    <dd>Just like a lot of <i>Fiasco</i> playbooks, this one presents an opening situation set in <b>Far Verona's Acheron Rho</b> that has many ways to deteriorate or escalate quickly.</dd>
                    <dt>Premise</dt>
                    <dd>In the halls of Noble House Triangulum's home - the technologically advanced megastructure called Habitat One - there's a tradition. </dd>
                    <dd>The scientifically inclined Nobles all need to pass a Maturation Exam in order to attain their full Noble title, which are the Discipline they dedicated their life to. Once passed, it is a reason to rejoice. </dd>
                    <dd>Except the closest Family, a variety of guests tend to get invited, including media, cooworkers and long time friends of the Family, joining the new member of the Discipline in ceremonial rituals of Passing and the festive drinking and dancing. </dd>
                    <dd>In light of the recent death of the Emperor and the coming election, it is also an occasion for the Noble House Triangulum to advertise their aspiration for peace in the Sector, and to show their major commitment to technologically advance the Empire by both sponsoring infrastructural change and granting equal access to education to all citizens. </dd>
                    <dd>In a world where rebel agents work to take Nobility's rightful place at the helm of the Empire and synthetic humans that escaped eradication scheme to overthrow humanity, a party like this encourages fun, laughter and lights up everyone's spirit while reputations are lost and enemies are made.</dd>
                    <dt>A word about <i>Fiasco</i></dt>
                    <dd>Fiasco is a role-playing game by Jason Morningstar, independently published by Bully Pulpit Games. It is marketed as a "GM-less game for 3–5 players, designed to be played in a few hours with six-sided dice and no preparation".</dd>
                </dl>
            </article>
        </div>
    )
}
const Translation = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Translation</h1></div>
                <a href={`https://tradervic12.itch.io`}>
                    <FontAwesomeIcon icon={faItchIo} className="fa-fw" />
                    ITCH.IO
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>Why do you translate games to Polish?</dt>
                    <dd>My kids have a knack for storytelling (are there kids that don't?) and being able to play narrative games with them has been one of my favourite pastimes. Unfortunately most of the finest games out there have not been translated to Polish. Fortunately, I can do that myself.</dd>
                    <dt>How do make your translations keep the original document layout?</dt>
                    <dd>Inkscape, mostly.</dd>
                    <dt>Which games have you translated?</dt>
                    <dd>Here are the two games I translated and was allowed to publish by the original creator:</dd>
                    <dt>Lasers & Feelings</dt>
                    <dd>You are the crew of the interstellar scout ship <b>Raptor</b>. Your mission is to explore uncharted regions of space, deal with aliens both friendly and deadly, and defend the <b>Consortium</b> worlds against space dangers. <b>Captain Darcy</b> has been overcome by the strange psychic entity known as Something Else, leaving you to fend for yourselves while he recovers in a medical pod.</dd>
                    <dd>What happens next? Play to find out!</dd>
                    <dt>Breakers</dt>
                    <dd>The magical realm of <b>Kyvr'ax</b> has collided with Earth, shearing the dimensions and creating a mashed-up borderland between our reality and the monster-infested domain of the wizard <b>Kai Shira Kai</b>. You play working-class heroes who explore the twisted <b>Break</b> seeking fame and fortune. But don't stay too long, or the <b>Cloud of Woe</b> will surely find you!</dd>
                    <dt>Other games (in the works)</dt>
                    <dd>The translation is not as time consuming as preparing the layout. So while some other games have been translated and played, they're not in the state where I could publish them.</dd>
                    <dd>Some of those titles include:</dd>
                    <dd>
                        <ul>
                            <li><a href="https://tabletop.itch.io/arcana-academy">Arcana Academy</a></li>  
                            <li><a href="https://crtgirl.itch.io/for-the-honor">For the Honor</a></li> 
                            <li><a href="https://emanoelmelo.itch.io/cbrpnk-core ">CBR+PNK</a> and subsequent extentions / appendices</li>                             
                        </ul>
                    </dd>

                </dl>
            </article>
        </div>
    )
}
const Triangulum = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Triangulum Lore</h1></div>
                <a href={`https://far-verona.fandom.com/wiki/House_Triangulum`}>
                    <i className="fas fa-link"> view</i>
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>What's Triangulum?</dt>
                    <dd>Well, <b>Rollplay: Far Verona</b> was a worldwide community of 2k+ creatives I participated in. As the main cast was roleplaying in a sci-fi world, the viewers had some limited governance of in-world events as well as the permission to write lore. It was a great (and successful) collaborative world-building experiment.</dd>
                    <dd><b>House Triangulum</b> was one of the noble houses of the Empire, the House of Science, a faction I belonged to and created lore for.</dd>
                    <dt>What lore was created?</dt>
                    <dd>It varied between Houses and other factions. It was cultural lore, the traditions and quirks of the people from various planets. It was historical lore, that supported the traditions and naming conventions. It was commericial lore, describing daily mundanities of life on some of the planets or cieties, daily products, food, fashion and government systems. There were character stories for minor NPCs that the main Game Master could use if he needed a colorful NPC to show up in the live show. And there were stories - snippets of life in the Acheron Rho sector (that was the name of the sector of the galaxy the show took place in).</dd>
                    <dt>What were your contributions?</dt>
                    <dd>I wrote for House Triangulum, the House of Science. I wrote about their home planet, Lovalace, that was a poisonous green wasteland, and about the place they now called home - Habitat One - an arcology in orbit of Lovalace which was the most advanced technlogical structure in the entire sector, excluding maybe the artificial orbital rings of Trillia IX.</dd>
                    <dd>I wrote how research and work are the most important and valued part of a trig's life. How work is a jealous lover and marriage in Triangulum is carefully planned not be become a compromise between family and research. I wrote about some scrutiny Triangulum faced from other Houses for participating in the creation of synthetic neurological systems used in creation of Synthetic Humans (or synths) and the rebellion that followed.</dd>
                    <dd>I wrote up some Triangulum characters, some technology they developed and used, and some minor stories about characters I found interesting.</dd>
                    <dt>What was the community about?</dt>
                    <dd>That's the beauty of it, really.</dd>
                    <dd>It was a dedicated fanbase of very hyped nerds, geeking out about this game world, yet there was no book or game or anything really - everything we were so hyped about was what we were ourself creating. It felt like a self-fulfilling prophecy and gave you the same headscratching as the question about the egg and the chicken.</dd>
                    <dd>I would never think something like this is possible, and I have never since seen anything like it.</dd>
                    <dt>Why did it end?</dt>
                    <dd>In the late 2010s a phenomenon called <i>cancel culture</i> was at it's peak. Mistakes were made by the Far Verona organizers putting the show under some unjust backlash, resulting in the show and it's subordinate lore creation efforts being put on an indefinite hiatus.</dd>
                    <dt>Is it really over?</dt>
                    <dd>Well, not exactly. While the Far Verona lore was kept frozen, the community was large enough to carry on. With the original organizers gone, they quickly went through a voting and discussion phase on a future direction and decided that work on a new setting should be started. </dd>
                    <dd>The new community was named <b>Unrestricted Lorefare</b>, and while smaller, to this day it continues to create lore for <a href="https://beaconspace.unrestrictedlorefare.com/wiki/Main_Page">Beacon Space</a>.</dd>
                </dl>
            </article>
        </div>
    )
}
const Stalwart = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Stalwart</h1></div>
                <a href={`https://tradervic12.itch.io/stalwart`}>
                    <FontAwesomeIcon icon={faItchIo} className="fa-fw" />
                    ITCH.IO
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>What's Stalwart?</dt>
                    <dd>It's a chess-like game that's fantasy themed. Think of it as the game wizards or intellectuals play in a fantasy world, where chess is unknown.</dd>
                    <dt>Why did you make this game?</dt>
                    <dd>Just like the LARP games I made, this game was also made for Flamberg - a fantasy LARP convention. I was asked by the organizers to come up with a chess-like in-world game for intellectuals, and that's what I made. It was somewhat popular after it premiered.
                    <blockquote>Sidenote: I also made a tavern card game for the in-world masses called <i>Red Dragon</i>, which was even more popular, but I have since lost the documents containing the card designs. Bummer.</blockquote>
                    I got back to the Stalwart project as I started to play games with my kids. They really liked the chess-like feel of the game that still allowed them to cast fireballs.</dd>
                    <dt>Why are there two barbarians?</dt>
                    <dd>Partly because the board is 6 by 6. Originally it was five pieces and a 5 by 5 board, but it made the Assassin so much more powerful, that I had to make the board 6 by 6.</dd>
                    <dd>And I chode to make more barbarians, because that represents the <i>horde</i> aspect of the barbarian tribes.</dd>
                    <dt>What's with those win conditions?</dt>
                    <dd>Believe it or not, these went though the most of testing. I know they don't feel perfect, but they really are the best for the game.</dd>
                </dl>
            </article>
        </div>
    )
}
const Writing = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>My Writing</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/Stories_compilation.pdf`} 
            download="Stories_compilation.pdf">
                <i className="fas fa-download"> Download</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>Blue Sun</dt>
                    <dd><b>A starcraft fanfic.</b> A short story about the personnel of a remote research and excavation base. A young geologist and a security officer bond under in the light of a blue star.</dd>
                    <dt>Mateusz</dt>
                    <dd><b>Current day monster hunters.</b> Inspired by the questions "What if humanity needed Witchers in our day and age?", set in Katowice, Poland, a story of a rag-tag group of talented individuals trying to leverage them against the forces of darkenss.</dd>
                    <dt>Sand and the Blue Sky</dt>
                    <dd><b>A postapocalyptic short story.</b> A little postnuclear action sprinkled with a small dose of humor and suspense.</dd>
                </dl>
            </article>
        </div>
    )
}
const Larp = () => {
    return (
        <div className="regular_page">
        <div className='heading_functional'>
            <div className="page_title"><h1>Live Action RP</h1></div>
            <a 
            href={`${process.env.PUBLIC_URL}/docs/flamberg.zip`} 
            download="flamberg.zip">
                <i className="fas fa-download"> Download</i>
            </a>
        </div>
            <article className="regular_content">
                <dl>
                    <dt>Flamberg</dt>
                    <dd>A fantasy LARP convention, held in a ruined castle in Mirów, Poland, back in 2001-2005, and in other locations later. It has since been discontinued.</dd>
                    <dt>Maggus LARP</dt>
                    <dd>As a player, you will take on the role of one of the Black Master's apprentices, each equipped with 8 points to allocate towards spells, arcane knowledge, scrolls, and magical items. You must strategize and form alliances—or betray them—as you navigate the treacherous castle ruins, using your acquired powers to survive and outwit your rivals.</dd>
                    <dt>Fallout LARP</dt>
                    <dd>Step into the chaotic and immersive world of "Wasteland Village", a dynamic live-action role-playing game set in the ruins of a post-apocalyptic castle. Players take on diverse roles, from desperate villagers and cunning traders to enigmatic tribals and remnants of a secretive enclave. Each character navigates a web of alliances, treachery, and survival, using unique skills and combat strategies to achieve their goals. Get ready for a blend of strategic combat, intriguing personal quests, and community-driven objectives, and prepare as the unnecessarily limiting <i>Speech</i> mechanic introduces a fun twist to the gameplay.</dd>
                </dl>
            </article>
        </div>
    )
}
const LotD = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>LotD</h1></div>
                <a href={`https://drive.google.com/file/d/1zrZJPfkaiv3Q-8ZSDPcTpwbw3Ib1GPul/view?usp=sharing`}>
                    <FontAwesomeIcon icon={faGoogleDrive} className="fa-fw" />
                    Download
                </a>
            </div>
            <article className="regular_content">
                <dl>
                    <dt>Lands of the Damned</dt>
                    <dd>On New Year's Eve 2001/2002 I was sick and had to stay home. I just received Neverwinter Nights for christmas, so I decided to try it out.</dd>
                    <dd>This wasn't a new game, no. It's been on the market for a while, but that was ok. It was already patched and fixed, had a couple of extensions, and mot importantly, had a bustling community of players.</dd>
                    <dd>The campaign felt boring and repetetive, nothing like what I was used to from games like Baldur's Gate, so I decided to see what Multiplayer had to offer.</dd>
                    <dd>The variety of games hosted was unexpected, and going playing through servers I finally stumbled onto Lands of The Damned, or World in the Shadow (the creators haven't decided on the name). Had conversations with the players and the scripter, and before I knew I was in the NWN Editor that same night, writing my first quests.</dd>
                    <dd>I got back to that server's owners the next day and offered to help with new areas, and before I knew I was part of the team.</dd>
                    <dt>Features</dt>
                    <dd>- Balance - The original creatrs were very keen on balancing all the hack'n'slash elements of the gameplay like CR and quantity of enemies and the corresponding loot drops.</dd>
                    <dd>- Lore-rich world - When I came in, I introduced minor quests and tried to fix continuity errors or add lore where it was missing.</dd>
                    <dd>- Non-linear game progression - A choice of areas for gaining XP on starting levels.</dd>
                    <dd>- Additional rules - teleportation, epic spells, other convenience spells and feats.</dd>
                    <dd>- Disabled pickpocketing - NWNScript didn't allow for modification of how pickpocketing worked, so we decided it's best to disable it.</dd>
                    <dd>- Random Quest System - a system to add some variety to low level quests</dd>
                    <dd>- Spell Componenent system - Required components to be in the player's inventory cast some of the spells. Seemed like a great idea, but was later removed as a nuisance.</dd>
                    <dd>- Small storylines - The player characters didn't have a backstory, and there was never a main plotine they could follow. Instead, they would stumble into several questlines that then pulled them deeper into the world.</dd>
                    <dt>Download</dt>
                    <dd><a href="https://drive.google.com/file/d/1zrZJPfkaiv3Q-8ZSDPcTpwbw3Ib1GPul/view?usp=sharing">Google Drive link</a></dd>
                </dl>
            </article>
        </div>
    )
}

const Blog20250203 = () => {
    return (
        <div className="regular_page">
            <div className='heading_functional'>
                <div className="page_title"><h1>Why Most Indies Focus on the Wrong Problems</h1></div>
                {/* <a href={`https://drive.google.com/file/d/1zrZJPfkaiv3Q-8ZSDPcTpwbw3Ib1GPul/view?usp=sharing`}>
                    <FontAwesomeIcon icon={faGoogleDrive} className="fa-fw" />
                    Download
                </a> */}
            </div>
            <article className="regular_content">
                <dl>
                    <dt>The One Thing Principle in Game Design</dt>
                    <dd>In game development, we often fall into a seductive trap: spreading our resources across numerous "B+ features" instead of focusing on the one "A+ problem" that could define our game's success.</dd>
                    <dd>This principle, known in business as the Theory of Constraints, is particularly crucial in indie game development, where resources are limited and differentiation is essential.</dd>
                    
                    <dt>The Hidden Cost of Feature Creep</dt>
                    <dd>Most indie developers spend their time on what I call "B+ problems" - features that feel good to implement because they're easier and provide quick wins, but don't significantly move the game forward.</dd>
                    <dd>They add crafting systems, side quests, and mini-games before perfecting their core gameplay loop.</dd>

                    <dt>The Power of the A+ Problem</dt>
                    <dd>The secret to breakthrough success lies in identifying and obsessing over your game's "A+ problem" - the one core mechanic or feature that, if executed exceptionally well, would make other features less critical or even irrelevant.</dd>
                    <dd>Consider these success stories:</dd>
                    <dd>- Vampire Survivors perfected its auto-attack roguelike mechanics before adding complexity</dd>
                    <dd>- Stardew Valley mastered its farming/life sim loop before expanding</dd>
                    <dd>- Among Us focused exclusively on its social deduction mechanics, keeping other elements minimal</dd>

                    <dt>How to Identify Your A+ Problem</dt>
                    <dd>Your A+ problem is typically:</dd>
                    <dd>- The core gameplay loop that makes your game unique</dd>
                    <dd>- The primary mechanic that players will engage with most</dd>
                    <dd>- The feature that, if removed, would make your game unrecognizable</dd>
                    <dd>For example, if you're making a heist game, your A+ problem isn't the base-building system or NPC relationships - it's making the actual heist mechanics feel exceptional.</dd>

                    <dt>Practical Implementation</dt>
                    <dd>Core Mechanic Focus:</dd>
                    <dd>- Spend 80% of your development time on your primary gameplay loop</dd>
                    <dd>- Prototype and iterate your core mechanic until it feels exceptional</dd>
                    <dd>- Get extensive feedback on your main feature before adding supplementary ones</dd>
                    <dd>Feature Evaluation:</dd>
                    <dd>- For each new feature, ask: "Does this enhance my core mechanic?"</dd>
                    <dd>- Be ruthless about cutting features that don't directly support your main gameplay</dd>
                    <dd>- Consider if a feature could be simplified or removed entirely</dd>

                    <dt>Moving Forward</dt>
                    <dd>The next time you're tempted to add another feature to your game, pause and ask:</dd>
                    <dd>- Is this enhancing my core mechanic?</dd>
                    <dd>- Am I avoiding a harder but more important problem?</dd>
                    <dd>- Would my resources be better spent perfecting what makes my game unique?</dd>
                    <dd>Remember: In game development, as in business, success often comes not from doing many things adequately, but from doing one crucial thing exceptionally well.</dd>
                </dl>
            </article>
        </div>
    )
}
const MainView = () => {
    return (
        <div className="main_page">
            <div className="container">
                <Link to="/heroic_blades" className="link">
                    <div className="row" >
                        <div className="row_image">
                            <img src={hb} alt="Heroic Blades" />
                        </div>
                        <div className="row_text">
                            <div className="row_tags">game ttrpg fantasy</div>
                            <div className="row_name">Heroic Blades</div>
                            <div className="row_content"><i>OSR</i> vibes meet <i>Blades in the Dark</i></div>
                        </div>
                    </div>
                </Link>
                <Link to="/lavernica" className="link">
                    <div className="row">
                        <div className="row_image">
                            <img src={lav} alt="Lavernica" />
                        </div>
                        <div className="row_text">
                            <div className="row_tags">game tabletop fantasy</div>
                            <div className="row_name">Lavernica</div>
                            <div className="row_content">A fantasy tabletop game of</div>
                            <div className="row_content">warfare, statecraft, deceit,</div>
                            <div className="row_content">visionary leaders </div>
                            <div className="row_content">and arcane secrets.</div>
                        </div>
                    </div>
                </Link>
                <Link to="/almanac" className="link">
                    <div className="row">
                        <div className="row_image">
                            <img src={almanac} alt="almanac" />
                        </div>
                        <div className="row_text">
                            <div className="row_tags">tools world-building</div>
                            <div className="row_name">Almanac</div>
                            <div className="row_content">A python library implementing</div>
                            <div className="row_content">a variety of random generators.</div>
                        </div>
                    </div>
                </Link>
                <Link to="/dwarves" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={dwarves} alt="Beards, Picks and Axes" />
                    </div>
                    <div className="row_text">
                        <div className="row_tags">board-game dwarves</div>
                        <div className="row_name">Beards, Picks<br/>and Axes</div>
                        <div className="row_content">A game about dwarven miners</div>
                        <div className="row_content">out do get that precious gold.</div>
                    </div>
                </div>
                </Link>
                <Link to="/heart_of_gold" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={lav_nega} alt="Lavernica: Heart of Gold" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">story fantasy</div>
                        <div className="row_name">Lavernica:<br/>Heart of Gold</div>
                        <div className="row_content">A set of short stories centering</div>
                        <div className="row_content">on a certain elven warrior.</div>
                    </div>
                </div>
                </Link>
                <Link to="/far_verona" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={far_verona} alt="Far Verona Stories" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">story sci-fi</div>
                        <div className="row_name">Far Verona Stories</div>
                        <div className="row_content">A selection of stories and chapters</div>
                        <div className="row_content">set in the <b>Far Verona</b> universe.</div>
                    </div>
                </div>
                </Link>                
                <Link to="/tecendil" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={tecendil} alt="Tecendil" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">linguistics tolkien tengwar</div>
                        <div className="row_name">Polish Tengwar Mode</div>
                        <div className="row_content">A quenya-based Polish mode for</div>
                        <div className="row_content"><i>tengwar</i> compatible with</div>
                        <div className="row_content">the official <b>Appendix E</b>.</div>
                    </div>
                </div>
                </Link> 
                <Link to="/maturation_fiasco" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={maturation_fiasco} alt="Maturation Fiasco" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">ttrpg fiasco sci-fi</div>
                        <div className="row_name">Maturation Fiasco</div>
                        <div className="row_content">A <i>FIASCO</i> playbook set</div>
                        <div className="row_content">in the <b>Far Verona</b> universe.</div>
                    </div>
                </div>
                </Link>              
                <Link to="/translation" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={translation} alt="Translation" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">ttrpg translation</div>
                        <div className="row_name">Translation</div>
                        <div className="row_content">Minigames from other authors</div>
                        <div className="row_content">translated to Polish.</div>
                    </div>
                </div>
                </Link>
                <Link to="/trig_lore" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={trig_lore} alt="Triangulum Lore" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">lore sci-fi</div>
                        <div className="row_name">Triagulum Lore</div>
                        <div className="row_content">Collaborative world-building</div>
                        <div className="row_content">for a faction of scientists</div>
                        <div className="row_content">in the <b>Far Verona</b> universe.</div>
                    </div>
                </div>
                </Link>
                
                <Link to="/stalwart" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={stalwart} alt="Stawart" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">tabletop chess-like</div>
                        <div className="row_name">Stalwart</div>
                        <div className="row_content">What if your fantasy world</div>
                        <div className="row_content">had it's own version of chess?</div>
                    </div>
                </div>
                </Link>
                
                <Link to="/writing" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={writing} alt="Lavernica: Heart of Gold" />
                    </div>                    
                    <div className="row_text">
                        <div className="row_tags">story fantasy</div>
                        <div className="row_name">Various Stories</div>
                        <div className="row_content">A collection of novellas, articles</div>
                        <div className="row_content">short stories and essays.</div>
                    </div>
                </div>
                </Link>
                <Link to="/larp" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={flamberg} alt="Lands of the Damned" />
                    </div>
                    <div className="row_text">
                        <div className="row_tags">larp</div>
                        <div className="row_name">Flamberg LARPs</div>
                        <div className="row_content">Two <i>Live Action RP</i> scenarios</div>
                        <div className="row_content">and rules for <b>Flamberg 2003</b>.</div>
                    </div>
                </div>
                </Link>
                <Link to="/lotd" className="link">
                <div className="row">
                    <div className="row_image">
                        <img src={lotd} alt="Lands of the Damned" />
                    </div>
                    <div className="row_text">
                        <div className="row_tags">pc-game mod</div>
                        <div className="row_name">Lands of the Damned</div>
                        <div className="row_content">A <i>Neverwiter Nights </i>module</div>
                        <div className="row_content">part hack'n'slash, part narrative,</div>
                        <div className="row_content">set in an orginal realm.</div>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    );
};

const Blog = () => {
    return (
        <div className="main_page">
            <div className="container">
                <Link to="/blog_20250203" className="link">
                    <div className="row" >
                        {/* <div className="row_image">
                            <img src={hb} alt="Heroic Blades" />
                        </div> */}
                        <div className="row_text">
                            <div className="row_tags">gaemdesign indie</div>
                            <div className="row_name">Why Most Indies Focus on the Wrong Problems</div>
                            <div className="row_content"><i>In game development, we often fall into a seductive trap: spreading our resources across numerous "B+ features" instead of focusing on the one "A+ problem" that could define our game's success. This principle, known in business as the Theory of Constraints, is particularly crucial in indie game development, where resources are limited and differentiation is essential...</i></div>
                        </div>
                    </div>
                </Link>
                
            </div>
        </div>
    );
};

const Footer = () => {
    return (
        <footer className='main_footer'>
            <div className='wrapper'>
                <ul className='social_footer'>
                    <li>                        
                        <Link to="https://github.com/andrzej-zielinski">
                            <FontAwesomeIcon icon={faGithub} className="fa-fw" />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://tradervic12.itch.io/">
                            <FontAwesomeIcon icon={faItchIo} className="fa-fw" />
                        </Link>
                    </li>
                    <li>                        
                        <Link to="https://x.com/TraderVic12">
                            <FontAwesomeIcon icon={faTwitter} className="fa-fw" />
                        </Link>
                    </li>
                    <li>                        
                        <Link to="https://www.deviantart.com/tradervic12">
                            <FontAwesomeIcon icon={faDeviantart} className="fa-fw" />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://www.patreon.com/TraderVic">
                            <FontAwesomeIcon icon={faPatreon} className="fa-fw" />
                        </Link>
                    </li>
                    <li>                        
                        <Link to="https://www.linkedin.com/in/andrzej-zieli%C5%84ski-a002a2bb/">
                            <FontAwesomeIcon icon={faLinkedin} className="fa-fw" />
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

const App = () => {
    return (
      <Router>
        <div>
          <TopBar />
            <Routes>
              <Route path="/" element={<MainView />} />
              <Route path="/news" element={<News />} />
              <Route path="/bio" element={<Bio />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/heroic_blades" element={<HeroicBlades />} />                 
              <Route path="/lavernica" element={<Lavernica />} />                 
              <Route path="/dwarves" element={<Dwarves />} />                 
              <Route path="/tecendil" element={<Tecendil />} />                 
              <Route path="/lotd" element={<LotD />} />                 
              <Route path="/almanac" element={<Almanac />} />                 
              <Route path="/far_verona" element={<FarVerona />} />                 
              <Route path="/stalwart" element={<Stalwart />} />                 
              <Route path="/maturation_fiasco" element={<Maturation />} />                
              <Route path="/heart_of_gold" element={<HeartOfGold />} />                 
              <Route path="/writing" element={<Writing />} />                 
              <Route path="/trig_lore" element={<Triangulum />} />                 
              <Route path="/translation" element={<Translation />} />                 
              <Route path="/larp" element={<Larp />} />                 
              <Route path="/blog_20250203" element={<Blog20250203 />} />                 
            </Routes>
            <Footer/>
          </div>
      </Router>
        
    );
};

export default App;